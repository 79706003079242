import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiFillLinkedin,
  AiFillInstagram,
} from "react-icons/ai";
import { SiGooglescholar, SiNotion } from "react-icons/si";
import { IoMdMail } from "react-icons/io";
import KellieImage from "../navbar/kellie_logo.png";
import { useDarkMode } from "../components/DarkModeContext";
import { FaSun, FaMoon } from "react-icons/fa";

const selectorLinks = {
  email: "mailto:kellie_sim@mymail.sutd.edu.sg",
  linkedin: "https://www.linkedin.com/in/kelliesim",
  instagram: "https://www.instagram.com/kelliepandasg",
  googlescholar: "https://scholar.google.com/citations?hl=en&user=FNMo_FoAAAAJ",
  notion: "https://kellief0rnia.notion.site"
};

const navigation = [
  { name: "Home", href: "/home", current: false },
  { name: "About", href: "/about", current: false },
  { name: "Research", href: "/research", current: false },
  { name: "Education", href: "/education", current: false },
  { name: "Projects", href: "/projects", current: false },
  { name: "Mental Health Work", href: "/mental-health", current: false },
  { name: "Work Experience", href: "/work", current: false },
];

const Sidebar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const selected = props.page;
  const { darkMode, setDarkMode } = useDarkMode();

  navigation.forEach((item) => {
    item.current = item.name === selected;
  });

  return (
    <div className="lg:flex lg:flex-col lg:w-64 lg:min-h-screen bg-lightBg dark:bg-darkBg p-4">
      <div className="flex items-center justify-between lg:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-lightText dark:text-darkText p-2"
        >
          {isOpen ? <AiOutlineClose size={24} /> : <AiOutlineMenu size={24} />}
        </button>
      </div>

      <div className={`${isOpen ? "block" : "hidden"} lg:block`}>
        <img
          className="h-24 w-24 rounded-full mb-4 mx-auto"
          src={KellieImage}
          alt="Kellie Sim"
        />
        <nav className="flex-1">
          {navigation.map((item) => (
            <Link to={item.href} key={item.name} className="block w-full">
              <span
                className={`block py-2.5 px-4 rounded-md text-left text-lg ${
                  item.current
                    ? "text-green font-semibold"
                    : "text-lightText dark:text-darkText hover:text-green"
                }`}
                aria-current={item.current ? "page" : undefined}
                onClick={() => setIsOpen(false)}
              >
                {item.name}
              </span>
            </Link>
          ))}
        </nav>
        <div className="mt-16 space-y-1">
          {selectorLinks.email && (
            <a
              href={selectorLinks.email}
              className="block py-2.5 px-4 rounded-md text-left text-base text-lightText dark:text-darkText hover:text-green flex items-center space-x-2"
            >
              <IoMdMail size={24} />
              <span>Email</span>
            </a>
          )}
          {selectorLinks.linkedin && (
            <a
              href={selectorLinks.linkedin}
              className="block py-2.5 px-4 rounded-md text-left text-base text-lightText dark:text-darkText hover:text-green flex items-center space-x-2"
            >
              <AiFillLinkedin size={24} />
              <span>LinkedIn</span>
            </a>
          )}
          {selectorLinks.instagram && (
            <a
              href={selectorLinks.instagram}
              className="block py-2.5 px-4 rounded-md text-left text-base text-lightText dark:text-darkText hover:text-green flex items-center space-x-2"
            >
              <AiFillInstagram size={24} />
              <span>Instagram</span>
            </a>
          )}
          {selectorLinks.googlescholar && (
            <a
              href={selectorLinks.googlescholar}
              className="block py-2.5 px-4 rounded-md text-left text-base text-lightText dark:text-darkText hover:text-green flex items-center space-x-2"
            >
              <SiGooglescholar size={24} />
              <span>Google Scholar</span>
            </a>
          )}
          {selectorLinks.notion && (
            <a
              href={selectorLinks.notion}
              className="block py-2.5 px-4 rounded-md text-left text-base text-lightText dark:text-darkText hover:text-green flex items-center space-x-2"
            >
              <SiNotion size={24} />
              <span>CV (Notion Version)</span>
            </a>
          )}
        </div>
        <div className="mt-24">
          <button
            onClick={() => setDarkMode(!darkMode)}
            className="block py-2.5 px-4 rounded-md text-left text-base text-lightText dark:text-darkText hover:text-green"
          >
            {darkMode ? <FaSun size={20} /> : <FaMoon size={20} />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
