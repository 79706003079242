import React from "react";

const Footer = () => {
  return (
    <footer className="p-4 shadow-md bg-lightBg dark:bg-darkBg">
      <div className="text-sm lg:text-base container text-center text-lightText dark:text-darkText">
        <p>&copy; 2025 Kellie Sim • Last Updated: February 2025</p>
      </div>
    </footer>
  );
};

export default Footer;
