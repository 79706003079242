import { Link } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import Footer from "../components/Footer";
import KellieLogo from "../navbar/kellie_logo.png";
import { publications } from "../data";

const Home = () => {
  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1">
        <Sidebar page={"Home"} />
        <main className="flex-1 pl-4 pr-8 py-4 lg:p-8 dark:bg-darkBg dark:text-darkText">
          <section className="text-left">
            <img
              className="h-32 w-32 rounded-full"
              src={KellieLogo}
              alt="Kellie Sim"
            />
            <h1 className="text-3xl lg:text-4xl font-bold mt-4 text-lightText dark:text-darkText">
              Kellie Sim
            </h1>
            <p className="text-lg lg:text-xl mt-2 text-green">
              PhD Student at Singapore University of Technology and Design
              (SUTD)
            </p>
          </section>
          <section className="mt-12">
            <p className="mt-4 text-md lg:text-lg leading-relaxed text-lightText dark:text-darkText">
              Hello! My name is Kellie, or sometimes known as ‘kelliepanda’ on
              the Internet. I am a PhD student at Singapore University of
              Technology and Design (SUTD), researching Human-AI Interaction and
              Mental Health.
            </p>
            <p className="mt-4 text-md lg:text-lg leading-relaxed text-lightText dark:text-darkText">
              My research focuses on leveraging AI to support mental health and
              enhance user experiences with empathetic and supportive human-AI
              interactions. A side interest of mine is in the area of
              neurodiversity, and how technology and neurodiversity can
              intersect to create more inclusive and supportive environments.
              See my{" "}
              <a
                href="/research"
                target="_blank"
                and
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                Research
              </a>{" "}
              page for more information on my research experience and
              publications.
            </p>
            <p className="mt-4 text-md lg:text-lg leading-relaxed text-lightText dark:text-darkText">
              I completed my undergraduate studies at SUTD, where I graduated
              with a Bachelor of Engineering (Computer Science and Design). I
              have been actively involved in student life and campus activities
              during my time at SUTD. I have held multiple leadership positions
              in student clubs and societies, and have been actively involved in
              community service and volunteer work. I also worked on several
              research projects during my undergraduate studies, where I gained
              experience in conducting research and writing research papers. You
              can find more information about my time at SUTD on these pages:{" "}
              <a
                href="/education"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                Education
              </a>{" "}
              and{" "}
              <a
                href="/projects"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                Projects
              </a>
              . You can also find out more about my other educational
              experiences there!
            </p>
            <p className="mt-4 text-md lg:text-lg leading-relaxed text-lightText dark:text-darkText">
              Outside of school, I was also involved in the local mental health
              scene, such as by being an avid advocate on my Instagram page (
              <a
                href="https://instagram.com/kelliepandasg"
                target="_blank"
                and
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                @kelliepandasg
              </a>
              ), a Community Peer Supporter under Youth Corps Singapore's{" "}
              <a
                href="https://linktr.ee/projectreground"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                Project Re:ground
              </a>
              , a Youth Fellow under{" "}
              <a
                href="https://mindline.sg"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                mindline.sg
              </a>
              , working at a local mental health startup (
              <a
                className="text-green hover:underline"
                href="https://kurakura.io/"
                target="_blank"
                and
                rel="noopener noreferrer"
              >
                Kura Kura
              </a>
              ), and through various other projects. My experience in the mental
              health space is detailed in my{" "}
              <a
                href="/mental-health"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                Mental Health Work
              </a>{" "}
              page.
            </p>
            <p className="mt-4 text-md lg:text-lg leading-relaxed text-lightText dark:text-darkText">
              Finally, check out my{" "}
              <a
                href="/work"
                target="_blank"
                rel="noopener noreferrer"
                className="text-green hover:underline"
              >
                Work Experience
              </a>{" "}
              page for more information on my past internships and work
              experiences!
            </p>
            <p className="mt-4 text-md lg:text-lg leading-relaxed text-lightText dark:text-darkText">
              My goal is to combine my technical skills with my passion for
              social impact to create technology that makes a positive
              difference in people’s lives.
            </p>
          </section>

          <section className="mt-12 text-left">
            <Link to="/about">
              <button className="bg-green px-10 py-2 rounded-xl text-lightText transition-transform transform hover:scale-105">
                Learn More About Me
              </button>
            </Link>
          </section>

          <section className="mt-12 text-left">
            <h2 className="text-3xl font-bold text-lightText dark:text-darkText">
              News and Updates
            </h2>
            <ul className="list-disc mt-4 text-lg text-lightText dark:text-darkText">
              <li className="mb-2">
                September 2024: Starting my PhD journey at SUTD, funded by the{" "}
                <a
                  href="https://www.a-star.edu.sg/Scholarships/for-graduate-studies/a-star-cis-scholarship"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline"
                >
                  A*STAR Computing and Information Science (ACIS) scholarship
                </a>
                ! Supervised by{" "}
                <a
                  href="https://kennychoo.net/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline"
                >
                  Assistant Professor Kenny Choo
                </a>{" "}
                (SUTD) and{" "}
                <a
                  href="https://www.a-star.edu.sg/i2r/about-i2r/i2r-management/jamie-ng"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline"
                >
                  Dr. Jamie Ng Suat Ling
                </a>{" "}
                (A*STAR).
              </li>
              <li className="mb-2">
                July 2024: Attended my first conference -{" "}
                <a
                  href="https://dis.acm.org/2024/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline
                "
                >
                  ACM Designing Interactive Systems (DIS) 2024
                </a>
                ! Presented our work on "Towards Understanding Emotions for
                Engaged Mental Health Conversations (
                <a
                  href="https://doi.org/10.1145/3656156.3663694"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline"
                >
                  link
                </a>
                ).
              </li>
              <li className="mb-2">
                May 2024: Graduated from SUTD with a Bachelor of Engineering in
                Computer Science and Design!
              </li>
            </ul>
          </section>

          <section className="mt-12 text-left">
            <h2 className="text-3xl font-bold text-lightText dark:text-darkText">
              Features
            </h2>
            <ul className="mt-4 text-lg text-lightText dark:text-darkText">
              <li className="mb-2">
                <span className="font-semibold">[28 September 2024] </span>
                <span className="font-normal">
                  Represented Project Re:ground, featured on Youth Corps
                  Singapore’s Instagram Account
                </span>
                <br />
                <a
                  href="https://www.instagram.com/youthcorpssg/reel/DAdOlmozyQ7/?hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline"
                >
                  Instagram Reel
                </a>
              </li>
              <li className="mb-2">
                <span className="font-semibold">[6 September 2024] </span>
                <span className="font-normal">
                  Shared about my thoughts on graduation, featured on SUTD’s
                  TikTok Account
                </span>
                <br />
                <a
                  href="https://www.tiktok.com/@sutd_sg/video/7378441803525115144"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline"
                >
                  TikTok Video
                </a>
              </li>
              <li className="mb-2">
                <span className="font-semibold">[12 April 2024] </span>
                <span className="font-normal">
                  新科大设计医院智能导航系统 助年长者看病凭脚印不迷路
                </span>
                <br />
                <a
                  href="https://www.zaobao.com.sg/news/singapore/story20240412-3394837"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline mr-2"
                >
                  Lianhe Zaobao (Original Article) [Link]
                </a>
                |
                <a
                  href="https://www.sutd.edu.sg/news-listing/sutd-designs-a-smart-navigation-system-that-uses-shoeprints-to-help-seniors-find-their-way-around-the-hospital"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 text-green hover:underline"
                >
                  English Translation [Link]
                </a>
                {""} | {""}
                <a
                  href="/files/glowingguide_cn.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline mr-2"
                >
                  Lianhe Zaobao (Original Article) [PDF]
                </a>
                |
                <a
                  href="/files/glowingguide_eng.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 text-blue-500 hover:underline"
                >
                  English Translation [PDF]
                </a>
              </li>
              <li className="mb-2">
                <span className="font-semibold">[13 July 2022] </span>
                <span className="font-normal">设IG账号宣导心理健康</span>
                <br />
                <a
                  href="https://www.zaobao.com.sg/news/singapore/story20220713-1292247"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline mr-2"
                >
                  Lianhe Zaobao (Original Article) [Link]
                </a>
                |
                <a
                  href="https://www.sutd.edu.sg/news-listing/using-an-ig-account-to-promote-mental-wellness"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 text-green hover:underline"
                >
                  English Translation [Link]
                </a>
                {""} | {""}
                <a
                  href="/files/kelliepandasg_cn.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline mr-2"
                >
                  Lianhe Zaobao (Original Article) [PDF]
                </a>
                |
                <a
                  href="/files/kelliepandasg_eng.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-2 text-blue-500 hover:underline"
                >
                  English Translation [PDF]
                </a>
              </li>
              <li className="mb-2">
                <span className="font-semibold">[1 September 2021] </span>
                <span className="font-normal">
                  Shared my thoughts on volunteering for our migrant brothers,
                  featured on MOM’s Facebook Account
                </span>
                <br />
                <a
                  href="https://www.facebook.com/photo.php?fbid=4294029307312970&id=103944372988172&set=a.111172512265358"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-green hover:underline"
                >
                  Facebook Post
                </a>
              </li>
            </ul>
          </section>

          <section className="mt-12 text-left">
            <h2 className="text-3xl font-bold text-lightText dark:text-darkText">
              Publications
            </h2>
            {publications.map((item) => (
              <div className="py-3" key={item.id}>
                <span dangerouslySetInnerHTML={{ __html: item.value }} />
                <br />
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:underline hover:font-semibold text-green text-sm lg:text-base mt-2"
                >
                  View Publication
                </a>
                {item.pdf && (
                  <a
                    href={item.pdf}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="hover:underline hover:font-semibold text-green text-sm lg:text-base mt-2 ml-4"
                  >
                    View PDF
                  </a>
                )}
              </div>
            ))}
          </section>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
